/******************************************************************************************
* Utility Classes (-)
* -- Single purpose classes that can be applied to individual elements as needed
*******************************************************************************************/


/*------------------------------------------------------------------------------
Background Colors
-------------------------------------------------------------------------------- */
.-bgc-black { background-color: $black !important; }
.-bgc-dark-text { background-color: $dark-text !important; }
.-bgc-medium-text { background-color: $medium-text !important; }
.-bgc-light-text { background-color: $light-text !important; }
.-bgc-border-gray { background-color: $border-gray !important; }
.-bgc-light-border-gray { background-color: $light-border-gray !important; }
.-bgc-light-bg { background-color: $light-bg !important; }
.-bgc-extra-light-bg { background-color: $extra-light-bg !important; }
.-bgc-white { background-color: $white !important; }
.-bgc-blue { background-color: $blue-tint !important; }
.-bgc-green { background-color: $blue-tint !important; }
.-bgc-blue { background-color: $green !important; }
.-bgc-purple { background-color: $purple !important; }
.-bgc-red { background-color: $red !important; }
.-bgc-yellow { background-color: $yellow !important; }


/*------------------------------------------------------------------------------
Text Colors
-------------------------------------------------------------------------------- */
.-c-black { color: $black !important; }
.-c-dark-text { color: $dark-text !important; }
.-c-medium-text { color: $medium-text !important; }
.-c-light-text { color: $light-text !important; }
.-c-border-gray { color: $border-gray !important; }
.-c-light-border-gray { color: $light-border-gray !important; }
.-c-light-bg { color: $light-bg !important; }
.-c-extra-light-bg { color: $extra-light-bg !important; }
.-c-white { color: $white !important; }
.-c-blue { color: $blue !important; }
.-c-blue-tint { color: $blue-tint !important; }
.-c-green { color: $green !important; }
.-c-purple { color: $purple !important; }
.-c-red { color: $red !important; }
.-c-yellow { color: $yellow !important; }
.-c-linked-in { color: $linked-in !important; }
.-c-facebook { color: $facebook !important; }
.-c-instagram { color: $instagram !important; }
.-c-youtube { color: $youtube !important; }


/*------------------------------------------------------------------------------
Text Styles
-------------------------------------------------------------------------------- */
.-fw-bold {font-weight: 500;}
.-style-italic {font-style: italic;}
.-fz-10 {font-size: 12px;}
.-fz-12 {font-size: 12px;}
.-fz-14 {font-size: 14px;}
.-fz-18 {font-size: 18px;}
.-ta-center {text-align: center;}


/*------------------------------------------------------------------------------
Borders
-------------------------------------------------------------------------------- */
// .-b-none {border: $border!important;}
// .-b-std {border: $border!important;}
// .-bt-std {border-top: $border!important;}
// .-bb-std {border-bottom: $border!important;}
// .-br-std {border-right: $border!important;}
// .-bbs-none {border-bottom-style: none!important;}


/*------------------------------------------------------------------------------
Nudge Elements
-------------------------------------------------------------------------------- */
// Targets all classes with "-nudge-"
[class*="-nudge-"] {position: relative!important;}

// Up
@for $i from 1 through 8 {
    .-nudge-u-#{$i} {
        top: #{$i *-1}px!important;
    }
}

// Down
@for $i from 1 through 8 {
    .-nudge-d-#{$i} {
        top: #{$i}px!important;
    }
}

@include sm-max {
    @for $i from 1 through 8 {
        .-nudge-d-#{$i}-sm-max {
            top: #{$i}px!important;
        }
    }
}


/*------------------------------------------------------------------------------
Margin Adjustments
-------------------------------------------------------------------------------- */

/* Top
--------------------------------------------- */
.-mt-neg-1 {margin-top: -1px!important;}

// Base margin-top classes
@for $i from 0 through 12 {
    .-mt-#{$i} {
        margin-top: #{$i}px !important;
    }
}

@for $i from 7 through 40 {
    .-mt-#{$i * 2} {
        margin-top: #{$i * 2}px !important;
    }
}

// xs
@include xs-max {
    @for $i from 0 through 12 {
        .-mt-xs-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-xs-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// sm
@include sm {
    @for $i from 0 through 12 {
        .-mt-sm-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-sm-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// sm-max
@include sm-max {
    @for $i from 0 through 12 {
        .-mt-sm-max-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-sm-max-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// md-max
@include md {
    @for $i from 0 through 12 {
        .-mt-md-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-md-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// md-only
@include md-only {
    @for $i from 0 through 12 {
        .-mt-md-only-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-md-only-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// md-max
@include md-max {
    @for $i from 0 through 12 {
        .-mt-md-max-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-md-max-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// lg
@include lg {
    @for $i from 0 through 12 {
        .-mt-lg-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-lg-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// lg-only
@include lg-only {
    @for $i from 0 through 12 {
        .-mt-lg-only-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-lg-only-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// lg-max
@include lg-max {
    @for $i from 0 through 12 {
        .-mt-lg-max-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-lg-max-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}

// lg-max
@include xl {
    @for $i from 0 through 12 {
        .-mt-xl-#{$i} {
            margin-top: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mt-xl-#{$i * 2} {
            margin-top: #{$i * 2}px !important;
        }
    }
}


/* Bottom
--------------------------------------------- */
// 0-12 (by 1)
@for $i from 0 through 12 {
    .-mb-#{$i} {
        margin-bottom: #{$i}px!important;
    }
}
// 14-96(by 2)
@for $i from 7 through 48 {
    .-mb-#{$i * 2} {
        margin-bottom: #{$i * 2}px !important;
    }
}
// Negative Margins – 
@for $i from 1 through 16 {
    .-mb-neg-#{$i} {
        margin-bottom: #{$i * -1}px !important;
    }
}

// xs
@include xs-max {
    @for $i from 0 through 12 {
        .-mb-xs-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-xs-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// sm
@include sm {
    @for $i from 0 through 12 {
        .-mb-sm-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-sm-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// sm-max
@include sm-max {
    @for $i from 0 through 12 {
        .-mb-sm-max-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-sm-max-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// md
@include md {
    @for $i from 0 through 12 {
        .-mb-md-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-md-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// md-only
@include md-only {
    @for $i from 0 through 12 {
        .-mb-md-only-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-md-only-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// md-max
@include md-max {
    @for $i from 0 through 12 {
        .-mb-md-max-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-md-max-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// lg
@include lg {
    @for $i from 0 through 12 {
        .-mb-lg-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-lg-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// lg-only
@include lg-only {
    @for $i from 0 through 12 {
        .-mb-lg-only-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-lg-only-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// lg-max
@include lg-max {
    @for $i from 0 through 12 {
        .-mb-lg-max-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-lg-max-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

// xl
@include xl {
    @for $i from 0 through 12 {
        .-mb-xl-#{$i} {
            margin-bottom: #{$i}px !important;
        }
    }

    @for $i from 7 through 40 {
        .-mb-xl-#{$i * 2} {
            margin-bottom: #{$i * 2}px !important;
        }
    }
}

/*------------------------------------------------------------------------------
Padding Adjustments
-------------------------------------------------------------------------------- */

/* All Sides
--------------------------------------------4 */

// 0-80(by 4)
@for $i from 0 through 20 {
    .-p-#{$i * 4} {
        padding: #{$i * 4}px !important;
    }
}


/* Top
--------------------------------------------4 */
.-pt-32 {
    padding-bottom: 32px;
    display: block;
}
.-pt-56 {padding-top: 56px;}

/* Left
--------------------------------------------4 */
.-pl-20 {padding-left: 20px;}

/* Right
--------------------------------------------4 */
.-pr-2 {padding-right: 2px;}
.-pr-24 {padding-right: 24px;}


/*------------------------------------------------------------------------------
Height Adjustments
-------------------------------------------------------------------------------- */
.-h-100vh {
    height: 100vh!important;
}



/*------------------------------------------------------------------------------
Width Adjustments
-------------------------------------------------------------------------------- */
// Min Width
@for $i from 1 through 300 {
    .-minw-#{$i} {
        min-width: #{$i}px!important;
    }
}

// Width
@for $i from 1 through 300 {
    .-w-#{$i} {
        width: #{$i}px!important;
    }
}

/*------------------------------------------------------------------------------
Display Adjustments
-------------------------------------------------------------------------------- */
.-d-block {display: block!important;}
.-d-flex {display: flex!important;}
.-d-flex-center {
    display: flex!important;
    justify-content: center;
    align-items: center;
}
.-d-flex-col {
    display: flex!important;
    flex-direction: column!important;
}

@each $i in 4, 8, 12, 16, 24, 32, 40 {
    .-d-flex-col-#{$i} {
        display: flex !important;
        flex-direction: column !important;
        gap: #{$i}px !important;
    }
}

.-d-flex {display: flex!important;;}

@each $i in 8, 12, 16, 24, 40 {
    .-d-flex-#{$i} {
        display: flex !important;
        gap: #{$i}px !important;
    }
}


.-d-flex-between {
    display: flex!important;
    justify-content: space-between;
}

.-d-none {
    display: none!important;
    margin: 0!important; // Allows use in a flex container that user gap
}

// Hidden
.-hidden { display: none !important; }
@include xs-max {.-hidden-xs-max { display: none !important; }}
@include sm {.-hidden-sm { display: none !important; }}
@include sm-max {.-hidden-sm-max { display: none !important; }}
@include md {.-hidden-md { display: none !important; }}
@include md-only {.-hidden-md-only { display: none !important; }}
@include md-max {.-hidden-md-max { display: none !important; }}
@include lg {.-hidden-lg { display: none !important; }}
@include lg-only {.-hidden-lg-only { display: none !important; }}
@include lg-max {.-hidden-lg-max { display: none !important; }}
@include xl {.-hidden-xl { display: none !important; }}

// Visible
[class*="-visible-"] {display: none;} // Hides .-visible- divs by default
@include xs-max {.-visible-xs-max { display: initial !important; }}
@include sm {.-visible-sm { display: initial !important; }}
@include sm-max {.-visible-sm-max { display: initial !important; }}
@include md {.-visible-md { display: initial !important; }}
@include md-only {.-visible-md-only { display: initial !important; }}
@include md-max {.-visible-md-max { display: initial !important; }}
@include lg {.-visible-lg { display: initial !important; }}
@include lg-only {.-visible-lg-only { display: initial !important; }}
@include lg-max {.-visible-lg-max { display: initial !important; }}
@include xl {.-visible-xl { display: initial !important; }}

/*------------------------------------------------------------------------------
Flex 
-------------------------------------------------------------------------------- */
// Flex Basis – fb-1 through fb-250 
@for $i from 1 through 450 {
    .-fb-#{$i} {
        flex-basis: #{$i}px!important;
    }
}

// Flex Grow
.-fg-0 {flex-grow: 0!important;}
.-fg-1 {flex-grow: 1!important;}

// Flex Shrink
.-fs-0 {flex-shrink: 0!important;}

// Gap
@for $i from 1 through 24 {
    .-gap-#{$i} {
        gap: #{$i}px!important;
    }
}

// Row Gap
.-r-gap-24 {row-gap: 24px;}
.-r-gap-32 {row-gap: 24px;}
.-r-gap-40 {row-gap: 40px;}

@include xs-max {
    .-r-gap-24-xs-max {row-gap: 24px;}
    .-r-gap-32-xs-max {row-gap: 32px;}
    .-r-gap-40-xs-max {row-gap: 40px;}
}

@include sm-max {
    .-r-gap-24-sm-max {row-gap: 24px;}
    .-r-gap-32-sm-max {row-gap: 32px;}
    .-r-gap-40-sm-max {row-gap: 40px;}
}

// Misc
.-jc-between {justify-content: space-between;}
.-jc-center {justify-content: center;}
.-jc-start {justify-content: start!important;}
.-ai-center {align-items: center;}
.-as-start {align-self: start!important;}
.-as-center {align-self: center!important;}
.-fw-wrap {flex-wrap: wrap;}


/*------------------------------------------------------------------------------
Word Break
-------------------------------------------------------------------------------- */
.-wb-break-all {word-break: break-all!important;}


/*------------------------------------------------------------------------------
Float
-------------------------------------------------------------------------------- */
.-float-left {float: left!important;}
.-float-right {float: right!important;}


